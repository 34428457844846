<script>
import { mapActions } from 'pinia'

import { usePageStore } from '@voix/store/pageStore'
import { SwiperSlide } from 'swiper/vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

import LazyLogosAgentCashPlus from '~/components/logos/AgentCashPlus.vue'
import LazyLogosHilton from '~/components/logos/Hilton.vue'
import LazyLogosZilara from '~/components/logos/HyattZilara.vue'
import LazyLogosZiva from '~/components/logos/HyattZiva.vue'
import LazyLogosZivaZilara from '~/components/logos/HyattZivaZilara.vue'
import LazyLogosJewel from '~/components/logos/Jewel.vue'
import LazyLogosJewelGrande from '~/components/logos/JewelGrande.vue'
import LazyLogosMarriotBonvoy from '~/components/logos/MarriotBonvoy.vue'
import LazyLogosPlaya from '~/components/logos/Playa.vue'
import LazyLogosSanctuary from '~/components/logos/Sanctuary.vue'
import LazyLogosSandpiper from '~/components/logos/Sandpiper.vue'
import LazyLogosSeadust from '~/components/logos/Seadust.vue'
import LazyLogosTapestryCollection from '~/components/logos/TapestryCollection.vue'
import LazyLogosTurquoize from '~/components/logos/Turquoize.vue'
import LazyLogosWyndham from '~/components/logos/Wyndham.vue'

export default {
  components: {
    SwiperSlide,
    LazyLogosAgentCashPlus,
    LazyLogosHilton,
    LazyLogosZilara,
    LazyLogosZiva,
    LazyLogosZivaZilara,
    LazyLogosJewel,
    LazyLogosJewelGrande,
    LazyLogosMarriotBonvoy,
    LazyLogosPlaya,
    LazyLogosSanctuary,
    LazyLogosSandpiper,
    LazyLogosSeadust,
    LazyLogosTapestryCollection,
    LazyLogosTurquoize,
    LazyLogosWyndham,
  },

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      animateIn: false,
    }
  },

  computed: {
    sliderStyles() {
      const obj = {}
      if (
        this.fields.imageMaxWidth.value
        && this.$voix.breakpoint !== 'default'
      ) {
        obj['max-width'] = `${this.fields.imageMaxWidth.value}px`

        if (this.fields.pullContentCenter.value !== true) {
          if (this.fields.imagesOnLeft.value === true)
            obj.float = 'right'
          else
            obj.float = 'left'
        }
      }

      return obj
    },

    textClasses() {
      const obj = { 'text-left': true, 'text-right': false }
      if (this.$voix.breakpoint !== 'default') {
        if (this.fields.pullContentCenter.value !== true) {
          if (this.fields.imagesOnLeft.value === true) {
            obj['text-left'] = true
            obj['text-right'] = false
          }
          else {
            obj['text-left'] = false
            obj['text-right'] = true
          }
        }
        else {
          obj['text-center'] = true
        }
      }

      return obj
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage },
      )
    },
  },

  mounted() {
    defineSlice({

      name: { label: 'Left Right Half Block', group: 'Sanctuary' },
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        subtitle: { type: 'text', label: 'Sub-Title', name: 'subtitle' },
        description: { type: 'wysiwyg', label: 'Description', name: 'description' },
        imagesOnLeft: { type: 'checkbox', label: 'Images on Left?', instructions: 'Images on right by default', default: false, name: 'imagesOnLeft' },
        disableGreyBg: { type: 'checkbox', label: 'Disable Grey BG?', default: false, name: 'disableGreyBg' },
        pullContentCenter: { type: 'checkbox', label: 'Center Copy', default: false, name: 'pullContentCenter' },
        image1: { type: 'media', label: 'Image', breakpoints: {
          lg: { width: 650, height: 650 },
          md: { width: 650, height: 650 },
          sm: { width: 350, height: 350 },
        }, name: 'image1' },
        image2: { type: 'media', label: 'Image 2', breakpoints: {
          lg: { width: 650, height: 650 },
          md: { width: 650, height: 650 },
          sm: { width: 350, height: 350 },
        }, name: 'image2' },
        image3: { type: 'media', label: 'Image 3', breakpoints: {
          lg: { width: 650, height: 650 },
          md: { width: 650, height: 650 },
          sm: { width: 350, height: 350 },
        }, name: 'image3' },
        image4: { type: 'media', label: 'Image 4', breakpoints: {
          lg: { width: 650, height: 650 },
          md: { width: 650, height: 650 },
          sm: { width: 350, height: 350 },
        }, name: 'image4' },
        link: { type: 'link', label: 'Link', name: 'link' },
        imageMaxWidth: { type: 'text', label: 'Images Max Width', name: 'imageMaxWidth' },
        svgLogoInColor: {
          type: 'checkbox',
          label: 'SVG Logo In Color',
          default: false,
        },
        svgLogo: {
          type: 'select',
          label: 'SVG Logo',
          options: {
            None: 'None',
            AgentCashPlus: 'AgentCashPlus',
            Hilton: 'Hilton',
            HyattZilara: 'HyattZilara',
            HyattZiva: 'HyattZiva',
            HyattZivaZilara: 'HyattZivaZilara',
            Jewel: 'Jewel',
            JewelGrande: 'JewelGrande',
            MarriotBonvoy: 'MarriotBonvoy',
            Playa: 'Playa',
            Sanctuary: 'Sanctuary',
            Sandpiper: 'Sandpiper',
            Seadust: 'Seadust',
            TapestryCollection: 'TapestryCollection',
            Turquoize: 'Turquoize',
            Wyndham: 'Wyndham',
          },
        },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },

  methods: {
    ...mapActions(useBookingWidgetStore, ['toggleBookingModal']),
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.animateIn = true
    },
    executeLink(e) {
      const href = e.target.href
      if (href.substr(href.length - 9) === '#book-now') {
        this.toggleBookingModal('ghost-btn-link')
      }
      else {
        if (e.target.target === '_blank') {
          const win = window.open(href, '_blank')
          win.focus()
          return
        }
        window.location.href = href
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshold: [0, .25] }"
    class="flex justify-center md:px-8 relative overflow-hidden"
  >
    <div class="container">
      <div
        class="text-center flex flex-col-reverse md:flex-row"
        :class="{
          'md:flex-row-reverse': fields.imagesOnLeft.value === true,
          'bg-grey-lightest': fields.disableGreyBg.value === false,
        }"
      >
        <transition name="slide">
          <div
            v-if="animateIn"
            :class="textClasses"
            class="from-left text-left w-full md:w-1/2 pt-10 px-8 pb-0 relative z-10"
          >
            <div class="relative z-10">
              <div v-if="fields.svgLogo.value !== 'None'" :class="{ 'flex justify-center': fields.pullContentCenter.value }">
                <LazyLogosAgentCashPlus v-if="fields.svgLogo.value === `AgentCashPlus`" class="mb-6 w-[200px]" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosHilton v-if="fields.svgLogo.value === 'Hilton'" class="mb-6 w-[140px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosZilara v-if="fields.svgLogo.value === 'HyattZilara'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosZiva v-if="fields.svgLogo.value === 'HyattZiva'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosZivaZilara v-if="fields.svgLogo.value === 'HyattZivaZilara'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosJewel v-if="fields.svgLogo.value === 'Jewel'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosJewelGrande v-if="fields.svgLogo.value === 'JewelGrande'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosMarriotBonvoy v-if="fields.svgLogo.value === 'MarriotBonvoy'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosPlaya v-if="fields.svgLogo.value === 'Playa'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosSanctuary v-if="fields.svgLogo.value === 'Sanctuary'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosSandpiper v-if="fields.svgLogo.value === 'Sandpiper'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosSeadust v-if="fields.svgLogo.value === 'Seadust'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosTapestryCollection v-if="fields.svgLogo.value === 'TapestryCollection'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosTurquoize v-if="fields.svgLogo.value === 'Turquoize'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
                <LazyLogosWyndham v-if="fields.svgLogo.value === 'Wyndham'" class="mb-6 w-[200px]" :fill="fields.svgLogoInColor.value" :class="{ 'float-right': !fields.imagesOnLeft.value }" />
              </div>

              <h4 v-if="fields.title.value" class="mb-1 clear-both">
                {{ fields.title.value }}
              </h4>

              <h6 v-if="fields.subtitle.value" class="mb-4 text-sanctuary-bright-blue">
                {{ fields.subtitle.value }}
              </h6>

              <div v-if="fields.description.value" class="leading-loose" v-html="fields.description.value" />

              <VoixLink
                :field="fields.link" class="py-3 px-6 text-white uppercase bg-nonPhotoBlue"
                @click.prevent="executeLink"
              />

              <div class="mt-8">
                <slot />
              </div>
            </div>
          </div>
        </transition>
        <transition name="slide">
          <div
            v-if="animateIn" class="from-right w-full md:w-1/2 relative z-10 flex "
            :style="sliderStyles"
          >
            <GeneralSwiper
              :devise="devise"
              class="w-full h-full flex"
            >
              <SwiperSlide
                v-if="fields.image1.value?.length" class="h-full w-full"
              >
                <VoixMedia
                  :field="fields.image1"
                  class="object-cover w-full h-full"
                />
              </SwiperSlide>
              <SwiperSlide v-if="fields.image2.value?.length" class="h-full w-full">
                <VoixMedia

                  :field="fields.image2"
                  class="object-cover w-full h-full"
                />
              </SwiperSlide>
              <SwiperSlide
                v-if="fields.image3.value?.length" class="h-full w-full"
              >
                <VoixMedia
                  :field="fields.image3"
                  class="object-cover w-full h-full"
                />
              </SwiperSlide>
              <SwiperSlide v-if="fields.image4.value?.length" class="h-full w-full">
                <VoixMedia

                  :field="fields.image4"
                  class="object-cover w-full h-full"
                />
              </SwiperSlide>
            </GeneralSwiper>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
