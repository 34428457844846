<script>
import { mapActions } from 'pinia'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    colors() {
      if (this.fields.fgColor.value || this.fields.bgColor.value) {
        if (this.fields.ghost.value) {
          return {
            color: this.fields.fgColor.value,
            borderColor: this.fields.fgColor.value,
          }
        }
        return {
          color: this.fields.fgColor.value,
          backgroundColor: this.fields.bgColor.value,
        }
      }

      return {}
    },
  },
  mounted() {
    defineSlice({ name: { label: 'Ghost Button Link', group: 'global' }, fields: { link: { type: 'link', label: 'Link', editorLabel: true, name: 'link' }, ghost: { type: 'checkbox', label: 'Ghost?', default: true, name: 'ghost' }, btnLink: { type: 'checkbox', label: 'Regular Link Style?', default: { href: '#', text: 'Book Now' }, name: 'btnLink' }, fgColor: { type: 'color', label: 'Text Color', name: 'fgColor' }, bgColor: { type: 'color', label: 'Background Color', name: 'bgColor' } }, slots: [] })
  },
  methods: {
    ...mapActions(useBookingWidgetStore, ['toggleBookingModal']),
    executeLink(e) {
      const href = e.target.href
      if (href.substr(href.length - 9) === '#book-now') {
        this.toggleBookingModal('ghost-btn-link')
      }
      else {
        if (e.target.target === '_blank') {
          const win = window.open(href, '_blank')
          win.focus()
          return
        }
        window.location.href = href
      }
    },
  },
}
</script>

<template>
  <a
    v-if="fields.link.value && fields.link.value.href !== '#book-now'"
    v-bind="$attrs"
    class="btn inline-block"
    :class="{
      'btn-ghost': fields.ghost.value,
      'bg-playa-action text-abs-white': !fields.ghost.value,
      'btn-text': fields.btnLink.value,
    }"
    :style="colors"
    :href="fields.link.value.href"
    :target="fields.link.value.target"
    @click.prevent="executeLink"
  >
    {{ fields.link.value.text }}
  </a>

  <PlayaBooking
    v-if="fields.link.value && fields.link.value.href === '#book-now'" class="inline-block wi-auto"
    v-bind="$attrs"
  >
    <button
      class="btn inline-block m-2"
      :class="{
        'btn-ghost': fields.ghost.value,
        'bg-playa-action text-abs-white': !fields.ghost.value,
        'btn-text': fields.btnLink.value,
      }"
    >
      {{ $t("book-now") }}
    </button>
  </PlayaBooking>
</template>
